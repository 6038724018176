@import "../../index.css";

.like-button-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.like-count{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: 'Source Serif 4', 'Bitter', system-ui, sans-serif;
    font-size: larger;
    font-weight: 600;
    padding: 1rem;
}