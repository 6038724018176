@import '../../index.css';

.about {
    width: var(--form-width);
    margin-inline: auto;
    padding: 2rem 0;
  }
  
  .about__section {
    margin-bottom: 2rem;
  }
  
  .about__section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .about__section p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .about__section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .about__section li {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 0.5rem;
  }
  
  .about__stats {
    display: flex;
    justify-content: space-around;
    margin-top: 4rem;
    flex-wrap: wrap;
  }
  
  .about__stats-item {
    border: 2px;
    margin: 1rem;
    text-align: center;
  }
  
  .about__stats-item h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .about__stats-item p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .about__contact {
    margin-top: 2rem;
  }
  
  .about__contact h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .about__contact p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .about__contact-links {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .about__contact-links a {
    text-decoration: none;
    color: var(--color-primary);
    border: 0.5rem solid var(--color-primary);
    box-shadow: 0.3rem 0.3rem 0.3rem var(--color-gray-700);
    padding: 0.5rem;
    border-radius: 1rem;
    transition: 0.3s;
  }
  
  .about__contact-links a:hover {
    text-decoration: none;
    color: #fff;
    background: var(--color-primary);
    border: 0.5rem solid #fff;
    box-shadow: 0.3rem 0.3rem 0.3rem var(--color-primary);
    padding: 0.5rem;
    border-radius: 1rem;
  }