@import '../../index.css';

nav {
    width: 100vw;
    height: 5rem;
    display: grid;
    place-items: center;
    background: rgb(245, 245,245);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: 4px solid var(--color-bg);
}

.nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.nav__logo {
    width: 3rem;
    display: block;
}

/*sphere*/
#sphere {
    top: 0rem;
    position: absolute;
    width: 8rem;
    height: 100%;
    margin: 0 auto;
    transform-style: preserve-3d;
    animation: rotate 7.5s infinite linear;
    z-index: 2;
  }
  
  .sphere .ring {
    position: absolute;
    top: 0.1rem;
    left: 0;
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 6px dotted;
    transition: 10s ease;
  }
  
  @keyframes rotate {
    to {
      transform: rotateY(360deg);
    }
  }

.sphere:hover {
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 10px var(--color-primary), 0 0 20px var(--color-primary), 0 0 30px var(--color-primary);
  }
  .ring:hover{
    cursor: pointer;
  }

.nav__menu {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.nav__menu a {
    transition: 0.3s ease;
    padding: 0.5rem;
}

.nav__menu a:hover {
    color: var(--color-primary);
    background: var(--color-gray-700);
    padding: 0.5rem;
    border-radius: 14px;
}

.nav__toggle-btn {
    display: none;
}

@media screen and (max-width: 800px) {
    .nav__container {
        position: relative;
        padding-top: 0rem;
        margin: 0.5rem;
    }

    .nav__menu {
        display: none;
        position: absolute;
        top: 5rem;
        right: 0;
        flex-direction: column;
        gap: 0;
    }

    .nav__menu.show {
        display: flex;
    }

    .nav__menu li {
        width: 100%;
        box-shadow: -2rem 4rem 4rem rgba(0, 0, 0, 0.26);
        animation: dropDown 500ms ease forwards;
        opacity: 0;
        transform: rotateX(90deg);
        transform-origin: top;
    }

    @keyframes dropDown {
        to {
            opacity: 1;
            transform: rotateX(0);
        }
    }

    .nav__menu li:nth-child(2) {
        animation-delay: 200ms;
    }

    .nav__menu li:nth-child(3) {
        animation-delay: 400ms;
    }

    .nav__menu li:nth-child(4) {
        animation-delay: 600ms;
    }

    .nav__menu li a {
        display: block;
        background: var(--color-white);
        padding: 0.85rem 2.5rem;
    }

    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.5rem;
        cursor: pointer;
        position: absolute; 
        color: var(--color-primary);
        top: 2rem; 
        right: 1rem; 
    }
    

    #sphere {
        top: 0rem;
        position: absolute;
        width: 5rem;
        height: 90%;
        margin: 0 auto;
        transform-style: preserve-3d;
        animation: rotate 7.5s infinite linear;
        z-index: 2;
      }
      
      .sphere .ring {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 6px dotted;
      }

      .sphere:hover {
        border-radius: 50%;
        box-shadow: 0 0 10px var(--color-primary), 0 0 20px var(--color-primary), 0 0 30px var(--color-primary);
      }
      
}
