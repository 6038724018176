
#services {
  background-color: var(--color-gray-900);
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  bottom: -6rem;
}

#services > h1 {
  color: white;
  margin: 1rem;
}
#services > p {
  margin: 1rem;
}

.services__container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 6rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

hr {
  border: 0.1rem solid #fff;
  width: 100vw;
}

.services__left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-right: 0.2rem solid #fff;
}

.services__left li {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  border: 1px solid var(--color-gray-300);
  border-radius: var(--radius-4);
  padding: 1.5rem;
  cursor: pointer;
  transition: 0.3s;
}

.howImage {
  width: 100%;
  height: auto;
  transform: rotateX(-65deg) rotate(20deg);
  transform-style: preserve-3d;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0 0 #f9f9fb, -1px 0 28px 0 #22215103, 3rem 3rem 1rem 0.5rem #fff;
  transition: 0.4s ease-in-out transform, 0.4s ease-in-out box-shadow;
}

.howImage:hover {
  width: 100%;
  height: auto;
  transform: translate3d(0px, -16px, 0px) rotateX(0deg) rotateZ(0deg);
  box-shadow: 0 0.3rem 0 0 #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01),
    0rem 0.1rem 0.5rem -0.5rem #fff;
}

#services > div > ul > li.service__item.account.active > div > h3 {
  color: var(--color-primary);
}
#services > div > ul > li.service__item.blog.active > div > h3 {
  color: var(--color-primary);
}
#services > div > ul > li.service__item.viewlike.active > div > h3 {
  color: var(--color-primary);
}

li.service__item.account:hover {
  color: var(--color-primary);
  border-right: 0.2rem solid var(--color-primary);
  margin-right: 0.5rem;
}

li.service__item.blog:hover {
  color: var(--color-primary);
  border-right: 0.2rem solid var(--color-primary);
  margin-right: 0.5rem;
}
li.service__item.viewlike:hover {
  color: var(--color-primary);
  border-right: 0.2rem solid var(--color-primary);
  margin-right: 0.5rem;
}

.services__left li span {
  background: var(--color-primary);
  color: var(--color-gray-100);
  padding: 1rem;
  border-radius: 50%;
  font-size: 1.3rem;
}
.services__left li h3 {
  color: white;
  margin-bottom: 0.5rem;
}

.services__right {
  border-left: 1px solid var(--color-gray-300);
  padding-left: 6rem;
}

.services__right h3 {
  margin-bottom: 1rem;
  color: white;
}

.services__right p {
  margin-bottom: 0.8rem;
}

@media screen and (max-width: 800px) {
  .services__container {
    display: grid;
    gap: 6rem;
    margin-top: 4rem;
  }

  .services__right {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid var(--color-gray-300);
  }
}
