@import "../../index.css";

.postAvatarImg{
  display: block;
  object-fit: cover;
  width: 100%;
  height: 0 auto;
}

.latest{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.thumbnail__img{
  height: 15rem;
  display: block;
  object-fit: cover;
  width: 100%;
}

/* hero */
.hero__box {
  position: relative;
  width: 100vw;
  height: 150vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--color-gray-900);
  color: #fff;
  margin-bottom: 2rem;
  overflow: hidden;
}

.subHero__box {
  max-width: 100%;
}

#root > section > div.hero__box > div > h1 {
  color: var(--color-primary);
  font-size: 5rem;
  text-shadow: 0 0 20px #fff, 0 0 40px #fff, 0 0 60px var(--color-primary);
  margin: 2.5rem;
}

#root > section > div.hero__box > div > h2 {
  color: white;
  margin: 2.5rem;
  font-weight: 500;
  text-transform: none;
}

/* post.tsx */
.posts__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}

#load {
  margin: auto;
  width: 100%;
  margin-top: 3rem;
  height: 50px;
  font-weight: bolder;
  font-size: large;
  cursor: pointer;
}


#loader{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  margin-top: 3rem;
  height: 50px;
  font-weight: bolder;
  font-size: large;
  cursor: pointer;
}

.post__title {
  transition: 0.3s;
  display:flex;
}
.post__title:hover{
  width: 100%;
  display:flex;
  background: var(--color-primary);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

/*Search posts*/
.search__container{
  margin: 2rem;
  padding: 2rem;
}

.search{
  color: var(--color-gray-900);
  transition: 0.3s;
}

.search:focus{
  border: 0.1rem solid var(--color-primary);
  color: #fff;
  background-color: var(--color-gray-900);
  font-family: "Source Serif 4","Bitter",Times,serif;
}

/* postitem.tsx */
.post {
  background: var(--color-white);
  padding: 1rem;
  border-radius: var(--radius-4);
  padding-bottom: 2rem;
  transition: var(--transition);
  cursor: default;
}

.post:hover {
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}

.post__thumbnail {
  border-radius: var(--radius-3);
  overflow: hidden;
  height: auto;
  transition: 0.3s;
}

.post__thumbnail:hover {
  scale: 1.15;
  border-radius: var(--radius-3);
  border: 0.2rem solid var(--color-primary);
}

.post__content {
  margin-top: 1.5rem;
}

.post__content h3 {
  margin: 1rem 0 0.6rem;
}

.post__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;
}

#btnCategory {
  font-weight: bold;
}
#btnCategory:hover{
  color: var(--color-gray-900);
  background-color: var(--color-primary);
}

/* postauthor.tsx */
.post-author {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.post__author-avatar {
  width: 2.5rem;
  aspect-ratio: 1/1;
  border-radius: var(--radius-2);
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .posts__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .post__thumbnail {
    height: fit-content;
  }

  #load {
    position: relative;
    margin-top: 3rem;
    justify-content: center;
    width: 100%;
  }


  #root > section > div.hero__box > div > h1 {
    color: var(--color-primary);
    font-size: 3rem;
    text-shadow: 0 0 20px #fff, 0 0 40px #fff, 0 0 60px var(--color-primary);
    margin: 2.5rem;
  }

  #root > section > div.hero__box > div > h2 {
    color: white;
    margin: 2.5rem;
    font-weight: 500;
    text-transform: none;
  }
}