@import "../../index.css";

body {
  background: rgb(244, 244, 244);
}

input {
  margin-bottom: 0.5rem;
}

select {
  margin-bottom: 1rem;
}
.ql-editor {
  background: var(--color-white);
  overflow: scroll;
  height: 10rem !important;
}

.file-input-label {
  height: 10rem;
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
}

.file-input-label input[type="file"] {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}


.uploadedImage{
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 2rem;
}

@media screen and (max-width: 800px) {
  .create-post {
    width: 100%;
  }

  .uploadedImage{
    width: 100%;
    height: 5rem;
    padding: 1rem;
    border-radius: 2rem;
  }
  select {
    margin-bottom: 1rem;
  }
}