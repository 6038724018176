.loader {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background: #fff;
}

.hotthorn__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
}
h1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 3rem;
  font-family: sans-serif, Impact, Haettenschweiler, "Arial Narrow Bold";
  color: var(--color-primary);
}

/* .loader__image {
  z-index: 10;
  width: 10.5rem;
  aspect-ratio: 1/1;
}
#root > div > div > img {
  border-radius: 50%;
} */

/*test*/
.loading-bar {
  width: 130px;
  height: 2px;
  margin: 0 auto;
  border-radius: 2px;
  background-color: #bbb1a1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transform: rotateY(0);
  transition: transform 0.3s ease-in;
}

.blue-bar {
  height: 100%;
  width: 68px;
  position: absolute;
  transform: translate(-34px);
  background-color: var(--color-primary);
  border-radius: 2px;
  animation: initial-loading 1.5s ease infinite;
}

@keyframes initial-loading {
  0% {
    transform: translate(-34px);
  }
  50% {
    transform: translate(96px);
  }
  100% {
    transform: translate(-34px);
  }
}
