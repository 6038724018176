@import '../../index.css';

.post-detail {
    width: var(--form-width);
    background: var(--color-white);
    margin-inline: auto;
    padding: 2rem 0;
}

.title{
    color: black;
}

.post-detail__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.post-detail__buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.post-detail__thumbnail {
    margin: 1rem 0;
    height: fit-content;
    max-height: 30rem;
    overflow: hidden;
}

.post-detail p {
    margin-bottom: 0.7rem;
}

.post-detail__developer-link {
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2.5rem;
    border-radius: 2rem;
}

.post-detail__developer-link h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.post-detail__developer-link a {
    text-decoration: none;
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    padding: 0.5rem;
    border-radius: 1rem;
}
.post-detail__developer-link a:hover{
    text-decoration: none;
    color: #fff;
    border: 2px solid #fff;
    padding: 0.5rem;
    border-radius: 1rem;
}

.post-detail__developer-link img {
    margin-top: 1rem;
    max-width: 100vw;
    border-radius: 1rem;
}


.share{
    display:flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}
#share{
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
}
.copied-message{
    font-weight: bold;
}

@media screen and (max-width: 800px){
    .post-detail{
        width: 100%;
        background: transparent;
        padding: 0;
    }

    .post-detail h1{
        margin-top: 1rem;
    }

    .post-detail__developer-link {
        margin: 0rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 2.5rem;
        border-radius: 2rem;
    }
    
    .post-detail__developer-link h3 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    
    .post-detail__developer-link a {
        text-decoration: none;
        color: var(--color-primary);
        border: 2px solid var(--color-primary);
        padding: 0.5rem;
        max-width: 50px;
        border-radius: 1rem;
    }
    .post-detail__developer-link a:hover{
        text-decoration: none;
        color: #fff;
        border: 2px solid #fff;
        padding: 0.5rem;
        border-radius: 1rem;
    }
    
    .post-detail__developer-link img {
        margin-top: 1rem;
        max-width: 100vw;
        border-radius: 1rem;
    }

    #share{

    }
}
