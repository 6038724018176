@import '../../index.css';

.dashboard__container{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.dashboard__post{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background: var(--color-white);
    padding: 0.5rem 1.2rem;
    border-radius: var(--radius-3);
}

.dashboard__post-info{
    display: flex;
    gap: 2rem;
    width: 100%;
    align-items: center;
}

.dashboard__post-thumbnail{
    width: 4rem;
    border-radius: var(--radius-1);
    overflow: hidden;
    transition: 0.3s;
}

.dashboard__post-thumbnail:hover{
    scale: 1.5;
}

.dashboard__post-actions{
    display:flex;
    gap: 1rem;
}

.thumbnails__img{
    height: 5rem;
    display: block;
    object-fit: cover;
    width: 100%;
}


@media screen and (max-width: 800px){
    .dashboard__post{
        flex-direction: column;
    }

    .dashboard__post-info{
        gap: 1.5rem;
    }

    .dashboard__post-thumbnail{
        width: 5rem;
    }
}