@import '../../index.css';

.center{
    text-align: center;
    padding-top: 5rem;
    margin-inline: auto;
    display: block;
    width: 100%;
}

.error-page h2{
    margin-top: 3rem;
}