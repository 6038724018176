@import "../../index.css";

.chatbot-container{
  position: fixed;
  bottom: 5rem;
  background-color: var(--color-gray-900);
  color: var(--color-white);
  border-radius: 1rem;
  margin: 2rem auto;
  padding: 1rem;
  width: 5rem;
  font-family: serif, Arial, Helvetica, sans-serif;
  transition: width 0.5s ease;
  overflow: hidden;
}

.chatbot-container.open {
  width: 15rem;
  height: 40rem;
}

.chatbot-toggle-button {
  background-color: var(--color-primary);
  color: var(--color-white);
  margin-bottom: 1rem;
  border-radius: 1rem;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  transition: .3s ease-in-out;
}

.chatbot-toggle-button:hover {
  color: var(--color-white);
  background-color: #ff6a00;
}

.question{
  padding: 1rem;
  background: black;
  font-size: larger;
  border-radius: 1rem;
  margin: 0.2rem;
}

.answer{
  padding: 1rem;
  background: black;
  font-size: larger;
  border-radius: 1rem;
  margin: 0.2rem;
}

.chatbot-content {
  max-height: 30rem; 
  overflow-y: auto; 
}

.chatbot-messages {
  color: var(--color-white);
}

.chatbot-message {
  height: 100%;
  background-color: var(--color-white);
  border-radius: 1rem;
  color: var(--color-primary);
  padding: 2rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.chatbot-options {
  margin-bottom: 1rem;
}

.chatbot-options button {
  margin-top: 1rem;
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  outline: none;
  transition: 0.3s ease-in-out;
}

.chatbot-options button:hover {
  background-color: #ff6a00;
}

.chatbot-close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  padding: 0;
  cursor: pointer;
  outline: none;
  margin-top: 0.3rem;
}

.chatbot-close-button:hover {
  background-color: #cc0000;
}
