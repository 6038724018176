@import '../../index.css';

form{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.form__error-message{
    background: var(--color-red);
    color: var(--color-white);
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
    border-radius: var(--radius-1);
    display: block;
    margin-bottom: 1rem;
}

.login{
    width: var(--form-width);
    margin-inline: auto;
    height: calc(100vh - 12rem);
    display: grid;
    place-items: center;
}

.login h2 {
    margin-bottom: 2rem;
}

.login small {
    display: block;
    margin-top: 1.5rem;
}

.login small a {
    color: var(--color-primary)
}

@media screen and (max-width: 800px){
    .login{
        width: 100%;
    }
}