@import '../../index.css';

.developers__container{ 
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
}
.developer.has-five-posts {
    --angle: 0deg;
    border-width: 0.2rem;
    border-style: dotted;
    border-image-source: linear-gradient(var(--angle),var(--color-primary),#fff700,#ffa601 10em );
    border-image-slice: 10;
    animation: 5s rotate linear infinite;
    -webkit-animation: 10s rotate linear infinite;
  }
  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
   
.developer{
    background: var(--color-white);
    padding: 1rem;
    border-radius: var(--radius-3);
    display: flex;
    gap: 1rem;
    transition: var(--transition);
}

.developer:hover{
    box-shadow: 0 1rem 1.5rem rgba(0, 0 , 0 , 0.07);
}

.developer__avatar{
    width: 5rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 0.3rem solid var(--color-bg);
    overflow: hidden;
}

.developer__info p {
    margin-top: 1rem;
}

@media screen and (max-width: 800px){
    .developers__container{
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }
}